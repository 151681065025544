import {Grid} from "@material-ui/core"
import React from "react"
import {SectionContainer, SectionPreTitle, SectionTitle} from "../../../components/Shared"
import {ItemCard} from "./ItemCard"
import {MarketplaceItem} from "../../../api/ParseObjects"

export const ItemSection = (props: { title: string, subtitle: string, items: MarketplaceItem[] }) => {
  return (
    <SectionContainer>
      <SectionPreTitle>{props.subtitle}</SectionPreTitle>
      <SectionTitle>{props.title}</SectionTitle>
      <Grid container spacing={4}>
        {props.items.map((item) => {
          return (
            <Grid item xs={12} sm={6} md={4}>
              <ItemCard item={item}/>
            </Grid>
          )
        })}
      </Grid>
    </SectionContainer>
  )
}
