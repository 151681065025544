import {graphql} from "gatsby"
import React from "react"
import {Layout} from "../../../components/Layout"
import {ItemSection} from "../item/ItemSection"
import {CategoryPageQuery} from "../../../../graphql-types"
import {toItem} from "../item/graphql"

export const CategoryPage = (props: { data: CategoryPageQuery }) => {
  const category = props.data.allMarketplaceCategory.edges[0].node
  const items = props.data.allMarketplaceItem.edges.map((_) => toItem(_.node))

  return (
    <Layout title={`Recalbox Marketplace > ${category.name}`}>
      <ItemSection
        items={items}
        title={"Last items"}
        subtitle={`What's new in recalbox marketplace ${category.name} category?`}
      />
    </Layout>
  )
}

export default CategoryPage

export const query = graphql`
	query CategoryPage($name: String!) {
		allMarketplaceCategory(filter: { name: { eq: $name } }) {
			edges {
				node {
					...MarketplaceCategoryFragment
				}
			}
		}
		allMarketplaceItem(filter: { category: { eq: $name } }) {
			edges {
				node {
					...MarketplaceItemFragment
				}
			}
		}
	}
`
