import styled from "@emotion/styled"
import {CardActionArea, CardActions, CardContent, CardMedia, Grid, Typography} from "@material-ui/core"
import {Rating} from "@material-ui/lab"
import React from "react"
import {CircularAvatar} from "../../../components/plus/CircularAvatar"
import {Link} from "../../../components/i18n/I18nLink"
import {FixedHeightImage, FullHeightCoverImage, GrowGrid} from "../../../components/Shared"
import {FluidObject} from "gatsby-image"
import {FocusableCard} from "../../myrecalbox/components/FocusableCard"
import {MarketplaceItem} from "../../../api/ParseObjects"

const ItemCardStyled = styled(FocusableCard)((props: any) => ({}))

const ItemCardActions = styled(CardActions)(({theme}) => ({
  display: "flex",
  justifyContent: "space-between"
}))

const ItemCardDescription = styled(Typography)(({theme}) => ({
  fontSize: "0.9rem",
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 3,
  WebkitBoxOrient: "vertical",
}))

const ItemCardSubtitle = styled(Typography)(({theme}) => ({
  fontSize: "1rem",
  marginBottom: "15px",
  fontWeight: 500,
  "&::before": {
    content: "' '",
    display: "inline-block",
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    marginRight: "10px",
    border: `2px solid ${theme.palette.secondary.main}`
  }
}))

const ItemCardTitle = styled(Typography)(({theme}) => ({
  fontWeight: 700
}))

const ItemCardMedia = styled(CardMedia)`
${({theme}) => `
  height: 100px;
  @media (min-width: ${theme.breakpoints.values.sm}px) {
    height: 120px;
  }
  @media (min-width: ${theme.breakpoints.values.md}px) {
    height: 200px;
  }
`}
`
const FullHeightCardActionArea = styled(CardActionArea)({
  height: "100%"
})
export const ItemCard = (props: { item: MarketplaceItem }) => {
  const item = props.item
  const avatarUrl = item.imagesType === "gatsby" ? item!.localAuthorImage!.childImageSharp!.fixed!.src! : item.authorImage._url
  const image = item.imagesType === "gatsby" ?
    <FixedHeightImage fluid={item!.localImages![0]!.childImageSharp!.fluid! as FluidObject}/>
    : <FullHeightCoverImage src={item.images[0]._url}/>

  return (
    <ItemCardStyled>
      <CardActionArea component={Link} to={item.url}>
        <ItemCardMedia>
          {image}
        </ItemCardMedia>
        <CardContent>
          <ItemCardTitle>{item.name}</ItemCardTitle>
          <ItemCardSubtitle>{item.category}</ItemCardSubtitle>
          <ItemCardDescription>{item.description}</ItemCardDescription>
        </CardContent>
        <ItemCardActions>
          <Grid item>
            <CircularAvatar
              value={85}
              online={false}
              size={25}
              avatarUrl={avatarUrl}
            />
          </Grid>
          <Grid item>By {item.authorName}</Grid>
          <GrowGrid/>
          <Rating name="read-only" value={item.mark} readOnly size={"small"}/>
        </ItemCardActions>
      </CardActionArea>


    </ItemCardStyled>
  )
}
